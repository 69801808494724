.background {
  display: flex;
  height: 100%;
}

.background i {
  font-size: 3em;
  align-self: center;
  color: inherit;
  padding: 0.5em;
}
