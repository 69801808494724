
.tabs > ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.tabs a {
  padding: 0 16px;
  font-weight: 500;
  font-size: 0.8rem;
  opacity: 0.7;
  min-height: 48px;
  max-width: 256px;
}

.tabs :global(a.active),
.tabs a:active {
  opacity: 1;
  border-bottom: 3px solid var(--button-primary-text-color);
}

@media (max-width: 520px) {
  .tabs a span {
    display: none;
  }
  .tabs a > i {
    padding-right: 0;
  }
}
