 
.box {
  display: flex;
  position: relative;
  margin: 0.2em;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
}

.box:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.icon {
  width: 3em;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  pointer-events: none;
  justify-content: center;
}

.box form {
  display: flex;
  height: 100%;
}

.box input {
  border-color: transparent;
  box-shadow: none;
  outline: none;
  opacity: 1;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  color: currentColor;
  border: 0;
  padding: 8px 8px 8px 3em;
  width: 8em;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.box input::placeholder {
  color: currentColor;
}

.box input:focus {
  width: 16em;
}
