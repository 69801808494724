
.fold-enter {
  margin-left: -256px;
}

.fold-enter-active {
  margin-left: 0px;
  transition: margin 300ms ease-in;
}

.fold-exit {
  margin-left: 0px;
}

.fold-exit-active {
  margin-left: -256px;
  transition: margin 300ms ease-in;
}

.fade-enter {
  bottom: -10px;
  opacity: 0;
}

.fade-enter-active {
  bottom: 0;
  opacity: 1;
  transition: opacity 500ms ease-in, bottom 500ms ease-in;
}

.fade-exit {
  bottom: 0;
  opacity: 1;
}

.fade-exit-active {
  bottom: -10px;
  opacity: 0;
  transition: opacity 500ms ease-in, bottom 500ms ease-in;
}
