.item {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: transparent;
  height: 100%;
  display: flex;
  border-radius: inherit;
}

.bouncing {
  transition: transform 0.5s ease-out;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  border-radius: inherit;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-radius: inherit;

  padding-right: 32px;
  color: rgba(255, 255, 255, 0.3);
  background-color: var(--button-primary-color);
  box-sizing: border-box;
}

.wrapper {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
  border-radius: inherit;
}
