
.link {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: inline-flex;
  color: inherit;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
}

.link > i, 
.link > img {
  padding-right: 0.5em;
  width: 24px;
  box-sizing: content-box;
}

.link > span {
  flex: 1 1 auto;
}

.badge {
  line-height: 1.5em;
  padding: 0 .5em;
  display: block;
  border-radius: 1.5em;
  float: right;
  font-size: small;
}
