.bg {
  width: 100%;
  height: 100%;
  max-width: 512px;
  max-height: 400px;
}

[data-theme="dark"] .bg {
  filter: invert(1);
}
