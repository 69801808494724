.layout {
  display: flex;
  height: inherit;
}

.layout > aside {
  width: 256px;
  height: 100%;
  flex-shrink: 0;
}

.layout > section {
  flex-grow: 1;
  flex-basis: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.fog {
  background-color: rgba(0, 0, 0, 0.24);
}

.mobile kbd {
  display: none;
}

@media (max-width: 767px) {
  .layout > aside {
    position: fixed;
    z-index: 998;
  }
  .fog {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 997;
  }
}
