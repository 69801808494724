
.button {
  composes: button from global;
  display: inline-block;
  color: var(--button-text-color);
  background-color: var(--button-default-color);
}

.button:hover {
  background-color: var(--button-default-hover-color);
}
.button:focus {
  outline: none;
}

.button:disabled,
.button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

.primary {
  color: var(--button-primary-text-color);
  background-color: var(--button-primary-color);
}

.primary:hover {
  background-color: var(--button-primary-hover-color);
}

.danger {
  color: var(--button-danger-text-color);
  background-color: var(--button-danger-color);
}

.danger:hover {
  background-color: var(--button-danger-hover-color);
}

.warning {
  color: var(--button-warning-text-color);
  background-color: var(--button-warning-color);
}

.warning:hover {
  background-color: var(--button-warning-hover-color);
}

.flat {
  background-color: transparent;
  box-shadow: none;
  vertical-align: text-top;
}

.button > i {
  vertical-align: middle;
  margin-right: 5px;
}
