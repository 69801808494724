.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.dialog {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  outline: none;
}

@media (max-width: 767px) {
  .dialog {
    transform: translate(4%, -50%);
    left: 0;
    width: 92%;
  }
}
