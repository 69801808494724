
a.button {
  line-height: 40px;
}

.button {
  display: inline-block;
  text-align: center;
  background: transparent;
  position: relative;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  border: transparent;
  cursor: pointer;
  color: inherit;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.button:focus {
  outline: none;
}

.button > i {
  vertical-align: middle;
}

.primary {
  color: var(--button-primary-color);
}

.floating.primary {
  background-color: var(--button-primary-color);
  color: white;
}

.floating.primary:hover {
  background-color: var(--button-primary-hover-color);
}

.loading > i {
  animation-name: rotate; 
  animation-duration: 2s; 
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.floating {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  box-shadow: 0px 2px 5px var(--panel-shadow-color);
  z-index: 99;
}

@keyframes rotate {
  from {transform: rotate(360deg);}
  to {transform: rotate(0deg);}
}
