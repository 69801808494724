
.menu {
  position: relative;
}

.menu > summary::-webkit-details-marker {
  display: none;
}
.menu > summary::-moz-list-bullet {
  list-style-type: none;
}
.menu > summary {
  list-style-image: none;
  list-style-type: none;
}

.nav {
  composes: container from global;
  position: absolute;
  z-index: 99;
  width: max-content;
  font-weight: 400;
  font-size: initial;
  background-color: var(--panel-body-color);
  color: var(--panel-text-color);
  box-shadow: 0 2px 15px 2px var(--panel-shadow-color);
}

.nav ul {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
}

.nav > ul > li {
  border-top: 1px solid var(--separator-default-color);
  margin: 0;
}

.nav > ul > li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.nav > ul > li:first-child {
  border-top: none;
}

.nav a {
  padding: 8px;
  white-space: nowrap;
  display: flex;
}
