.header > h1 {
  opacity: 1 !important;
  font-size: 1.1em !important;
}

.header > h1 > small {
  font-size: small;
  font-variant: all-small-caps;
  color: #0000009c;
  font-weight: 500;
  display: block;
  opacity: .8;
}

.header > h1 a {
  text-decoration: none;
}

.header > h1 > small a {
  color: inherit;
  display: inline-flex;
}

.header > h1 > span a {
  color: black;
}

.header > h1 > small a:after {
  content: "•";
  padding: 0 .5em;
}

.header > h1 a > i {
  font-size: inherit;
  font-variant: initial;
  align-self: center;
  padding-left: .5em;
}
