
.box {
  background-color: var(--box-bg-color);
  padding: 1em;
  margin-bottom: 1em;
  color: var(--box-text-color);
  position: relative;
  overflow: hidden !important;
}

.box > legend {
  display: contents;
  font-size: larger;
  color: var(--panel-text-color);
}

.box > p {
  font-style: italic;
  color: inherit;
}

.warning {
  color: var(--button-warning-text-color);
  background-color: var(--button-warning-color);
}

.warning::before {
  content: "warning";
}

.danger > legend,
.warning > legend {
  color: white
}

.danger {
  color: var(--button-danger-text-color);
  background-color: var(--button-danger-color);
}

.danger::before {
  content: "danger zone";
}

.warning::before,
.danger::before {
  right: 0;
  position: absolute;
  font-size: 4em;
  bottom: -.25em;
  opacity: 0.2;
  text-transform: uppercase;
}
