.panel {
  composes: container from global;
  margin-top: 3vh;
  margin-bottom: 3vh;
  max-width: 936px;
  display: flex;
  flex-direction: column;
  color: var(--panel-text-color);
}

.panel:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.panel > header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: var(--panel-header-color);
  padding: 0.5em;
  min-height: 2em;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  flex: 0 0 auto;
}

.panel > header > h1 {
  flex: 1 1 auto;
  font-size: 1.2em;
  font-weight: 500;
  margin: 0;
  align-self: center;
  opacity: 0.6;
  padding-left: 0.5em;
}

.panel > header > a,
.panel > header > button {
  margin-left: 1em;
}

.panel > header > div {
  display: flex;
  align-items: flex-start;
}

.panel > article,
.panel > section {
  overflow: auto;
  flex: 1 1 auto;
  position: relative;
  background-color: var(--panel-body-color);
}

.panel > section {
  padding: 1em;
}

.panel > footer {
  display: flex;
  justify-content: flex-end;
  flex: 1 0 auto;
  padding: 0.5em;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background-color: var(--panel-footer-color);
}

.panel > footer > button {
  margin-left: 1em; 
}

@media (max-width: 767px) {
  .panel {
    max-width: 100%;
    margin: initial;
  }
}
