.userInfos {
  display: flex;
  align-content: center;
  flex: 0 0 auto;
}

.userInfos > a {
  border-radius: 50%;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: transparent;
  padding: 4px;
  overflow: visible;
  display: inline-flex;
}

.userInfos > a:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.userInfos > a > img {
  border-radius: 50%;
  max-width: 48px;
}

.userInfos > span {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: center;
  overflow: hidden;
}

.userInfos > span > strong {
  font-size: 16px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.userInfos > span > small {
  opacity: .6;
  font-size: 12px;
  font-weight: 200;
}
