.appBar {
  display: flex;
  background-color: var(--header-bg-color);
  color: var(--header-text-color);
  min-height: 1em;
  flex: 1 0 auto;
  align-content: center;
  padding-left: .5em;
  padding-right: 1em;
}

.appBar h1 {
  margin: 0;
  font-size: 18px;
  flex: 1 1 auto;
  align-self: center;
}

.appBar > h1+a {
  color: inherit;
  text-decoration: none;
  align-self: center;
  opacity: .8;
  font-size: smaller;
}
.appBar > h1+a:hover {
  opacity: 1;
}
