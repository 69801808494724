
.menu_item {
  display: flex;
  align-items: center;
}

.menu_item span, .menu_item kbd, .menu_item i {
  margin: 8px;
}

.toggle_menu_item {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
}

.toggle_menu_item li {
  background-color: var(--input-bg-color);
}
.toggle_menu_item li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.toggle_menu_item input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.toggle_menu_item label {
  display: block;
  cursor: pointer;
  color: var(--panel-text-color-alt);
}

.toggle_menu_item input[type="radio"]:checked + label {
  color: var(--button-primary-color);
}
