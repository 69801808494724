
.overlay {
  z-index: 999;
}

.blur {
  position: absolute !important;
  background-color: #ffffff80;
  height: 100%;
  width: 100%;
  text-align: center;
}
