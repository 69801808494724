
.center {
  position: relative;
  height: 100%;
}

.center > div {
  position: absolute;
  display: initial;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  margin: 0;
}
