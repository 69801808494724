[data-theme="dark"] {
  --error-color: #cd5356;
  --warning-color: #ff922f;
  --main-bg-color: #1e1e1f;
  --navbar-bg-color: #18202c;
  --navbar-header-text-color: white;
  --navbar-header-bg-color: #232f3e;
  --navbar-header-shadow-color: #404854;
  --navbar-link-color: rgba(255, 255, 255, 0.7);
  --navbar-link-hover-color: rgba(255, 255, 255, 0.08);
  --navbar-link-active-color: #3689ae;
  --header-text-color: #bdbdbd;
  --header-bg-color: #313235;
  --button-text-color: #484848;
  --button-default-color: #8e8e8e;
  --button-default-hover-color: #959595;
  --button-primary-color: #196e95;
  --button-primary-text-color: #cacaca;
  --button-primary-hover-color: #1598d5;
  --button-danger-color: #8d2526;
  --button-danger-text-color: #cacaca;
  --button-danger-hover-color: #a11f21;
  --button-warning-color: #ac6225;
  --button-warning-text-color: #cacaca;
  --button-warning-hover-color: #8f511f;
  --separator-default-color: #5f5f5f;
  --panel-header-color: #515151;
  --panel-body-color: #313235;
  --panel-text-color: #eaebec;
  --panel-text-color-alt: #bec2c7;
  --panel-footer-color: #515151;
  --panel-shadow-color: #171717;
  --box-bg-color: #5b5b5b;
  --box-text-color: #bdbdbd;
  --table-separator: #a7a7a70f;
  --input-text-color: #bdbdbd;
  --input-bg-color: #5f5f5f;
}
