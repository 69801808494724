.settings section > header {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(0,0,0,.12);
}

.settings section > header > h2 {
  flex-grow: 1;
  font-weight: 400;
  margin: 0;
  align-self: center;
}
.settings section > header > h2 > svg {
  margin-right: .5em;
  max-height: 2em;
  max-width: 2em;
  vertical-align: middle;
}

.settings section > header > a,
.settings section > header > button {
  align-self: flex-start;
}

.settings section li a {
  color: inherit;
  font-weight: 500;
}
